<div class="container">
    <div class="mat-elevation-z8">
        <form class="form" [formGroup]="OTAssignfilterForm">
            <div class="col-md-12" style="white-space: nowrap;">
                <h2 class="pt-3 float-left">Manage OT</h2>
                <div class="float-right pt-3">
                    <button mat-flat-button [routerLink]="['/OT-assign']">
                        <span class="material-icons">
                            add
                        </span>
                        <span>Assign OT</span>
                    </button>
                </div>
            </div>
            <div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>&nbsp;Date</mat-label>
                        <input matInput (click)="picker.open()" [errorStateMatcher]="matcher"
                          formControlName="date" [matDatepicker]="picker" placeholder="select start date" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker touchUi></mat-datepicker>
                        <mat-error *ngIf="validate.date.hasError('required')">
                          Please select <strong>Date</strong>
                        </mat-error>
                      </mat-form-field>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-12">
                    <button type="button" class="btn btn-primary btm-sm" (click)="search()"
                        style="position: relative;bottom:-22px;font-size: 14px;">Search</button>
                </div>
                <div class="col-md-12 col-lg-8 col-md-8 ">
                    <div class="float-right" *ngIf="filterData.length>0">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-icon matPrefix>search</mat-icon>
                            <mat-label>Search</mat-label>
                            <input matInput (input)="applyFilter($event)" placeholder="Search UserName">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Select Client </mat-label>
                        <mat-select placeholder="Select client" formControlName="client"
                            (selectionChange)="isselectedclient($event)">
                            <mat-option value="0"> -- Select client -- </mat-option>
                            <mat-option *ngFor="let user of userlist"  [value]="user.userId">{{user.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="error_msg" *ngIf="user_required">*Please select user</span> 
                </div> -->
                <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Select Employee</mat-label>
                        <mat-select placeholder="Select employee" formControlName="employee"
                            (selectionChange)="isselectedname($event)">
                            <mat-option value="0"> -- Select employee -- </mat-option>
                            <mat-option *ngFor="let geofence of geoList"  [value]="geofence.geofenaceId">{{geofence.geofenaceName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span> 
                </div> -->

            </div>
            <div class="table-responsive" *ngIf="filterData.length>0">
                <table mat-table [dataSource]="dataSource">
                    <div class="col-md-12">
                        <!-- <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>Sl.No</b> </th>
                            <td mat-cell  *matCellDef="let element; let i =index"> {{i+1}}  </td> 
                        </ng-container> -->
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
                                <b>SI.NO</b>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ currentPageOffset + i + 1 }}
                            </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="username">
                            <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>Employee Name/Number</b> </th>
                            <td mat-cell *matCellDef="let element" style="white-space: nowrap;">{{element.employee.employeeNumber}} - {{element?.employee?.user?.name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="otdate">
                            <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>OT Date</b> </th>
                            <td mat-cell *matCellDef="let element"> {{element?.otDate}}</td>
                        </ng-container>
                        <ng-container matColumnDef="otHours">
                            <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>OT Hours</b> </th>
                            <td mat-cell *matCellDef="let element"> {{element?.otHours}}</td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="clientName">
                            <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>CLIENT NAME</b> </th>
                            <td>Clicnt name here</td>
                        </ng-container>
                        <ng-container matColumnDef="clientLocation">
                        <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>CLIENT GEO LOCATION</b> </th>
                        <td>client location</td>
                    </ng-container> -->

<!-- 
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>STATUS</b> </th>
                            <td mat-cell *matCellDef="let element"> {{element?.status?.statusName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="view">
                            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>ACTION</b> </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button [matMenuTriggerFor]="beforeMenu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <div class="side_setting">

                                    <mat-menu #beforeMenu="matMenu" xPosition="after">
                                        <a mat-menu-item [routerLink]="['/assign']">
                                            Edit
                                            Geofence</a>
                                        <button mat-menu-item *ngIf="element.status.statusId==1"
                                            (click)="updateStatus(element.userGeofenceId,2)">InActive</button>
                                        <button mat-menu-item *ngIf="element.status.statusId==2"
                                            (click)="updateStatus(element.userGeofenceId,1)">Active</button>
                                    </mat-menu>
                                </div>
                            </td>
                        </ng-container> -->
                    </div>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="pl-3 pr-3" colspan="4" align="center">
                            <div class="alert alert-secondary" style="height: 60px;width: 115%;">
                                <h4 class="pt-2">No OT Assign List Found</h4>
                            </div>
                        </td>
                    </tr>
                    <!-- <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="6" id="nodata" style="text-align: center;">No data found
                        <td>
                    </tr> -->

                </table>
            </div>
        </form>
        <div class="p-3" *ngIf="nodata">
            <div class="no-data card text-center">
                <div class="card-body">No Data</div>
            </div>
        </div>
        <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5,10,20,30,50,100]"></mat-paginator>
        <!-- <mat-paginator  [pageSizeOptions]="[5,10,20,30,50,100]"   showFirstLastButtons ></mat-paginator>  -->
    </div>
</div>