import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import Swal from 'sweetalert2';
import { AuthService } from '../auth.service';
import { MessagingService } from 'src/app/service/messaging.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  hide = true;
  email: boolean = true;
  /* form */
  loginForm!: FormGroup;

  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private  messagingService : MessagingService
  ) { }

  ngOnInit(): void {
    if (this.email) {
      this.loginForm = this.formBuilder.group({
        username: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
        password: ['', [Validators.required]]
      });
    } else {

    }
  }

  get f() { return this.loginForm.controls; }

  change_field() {
    this.email = !this.email
    console.log(this.email)
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true



    // setTimeout(() => {
    //   this.loading = false
    //   this.router.navigate(['/user'])
    // }, 2000)
    let x = this.loginForm.value;

    console.log(x)
    this.authService.logintest(this.f.username.value, this.f.password.value).subscribe(
      (d: any) => {
        console.log(d);
        this.loading = false
        
        // if (d.userType[0].userTypeId == 3) 
        // {
        //   Swal.fire({
        //     text: 'Access Denied',
        //     icon: 'info',
        //   })
        //   return
        // }
        console.log("Login",d);
        localStorage.setItem('PrintLok$', JSON.stringify(d));
        if (d) {
          Swal.fire({
            text: 'You Have Logged In Successfully',
            icon: 'success',
          })
        }
         if(d.userType[0].userTypeName == 'SUPER ADMIN') 
         {
          this.router.navigate(['/org-list']);
         }
         else if(d.userType[0].userTypeId==3){
            console.log("Secondary User");
            this.router.navigate(['/loginoutReports']);
         }
         else{
          // alert("else ")
         this.messagingService.requestPermission();
          this.router.navigate(['/']);
         }
       
      },
      err => {
        console.log("Error");
        this.loading = false
        console.log(err)
        let x = err.error.description
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.description)
          if (err) {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }

  forget_password(){
   // alert("forget");
    this.router.navigate(['/forget-password']);
  }
}
