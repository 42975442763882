<div class="container">
    <div class="mat-elevation-z8">
      <form  [formGroup]="AssigngeofenceForm"> 
        <div class="col-md-12" style="white-space: nowrap;">
            <h2 class="pl-5 pt-3 float-left">Assign Client Employees</h2>
            <div class="float-right pt-3">
                <button mat-flat-button   [routerLink]="['/create-client-assign']">
                    <span class="material-icons">
                    add
                    </span>
                    <span>Assign Client Employees</span>
                </button>
            </div>
        </div>
        <div>
            <div class="col-md-12">
                <div class="float-right" *ngIf="filterData.length>0" style="width: 100%;"  >
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix>search</mat-icon>
                            <mat-label>Search</mat-label>
                            <!-- <input  matInput (keyup)="applyFilter($event)"  placeholder="search geofenacename" #input> -->
                            <input matInput (input)="applyFilter($event)" placeholder="Search cleient/employee">
                        </mat-form-field>
                </div>
           </div>    
        </div>
        <div class="row pl-5" style="">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Choose Client </mat-label> 
                        <mat-select placeholder="Select Client" formControlName="client"
  (selectionChange)="isselecteduser($event)">
                            <mat-option value="0"> -- Select Client -- </mat-option>
                            <ng-container *ngIf="setBoolean">
                              <mat-option *ngFor="let users of clientList" [value]="users.clientId">
                                {{users.clientCode}} - {{users.clientName}}
                              </mat-option>
                            </ng-container>                        </mat-select>
                    </mat-form-field>
                    <!-- <span class="error_msg" *ngIf="user_required">*Please select user</span>  -->
               </div>
                <!-- <div class="col-lg-3 col-md-3 col-sm-12"> -->
                        <!-- <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                            <mat-label>Choose Employee Name</mat-label> 
                            <mat-select placeholder="Select Geofence Name" formControlName="geofenceName"  (selectionChange)="isselectedname($event)">
                            <mat-option value="0"> -- Select Employee Name -- </mat-option>
                            <mat-option *ngFor="let geofence of geoList"  [value]="geofence.geofenaceId">{{geofence.geofenaceName}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <mat-form-field class="example-full-width" appearance="outline" style="display: inline;"> -->
                            <!-- <mat-label class="">Choose Employee</mat-label> 
                                <mat-select formControlName="geofenceName"  #select (click)="isselectedname($event)"
                                multiple
                                placeholder="Select Employee Name" >
                                    <div class="select-all">
                                        <mat-checkbox
                                        [(ngModel)]="allSelected"
                                        [ngModelOptions]="{standalone: true}"
                                        type="checkbox"
                                        (change)="toggleAllSelection()">
                                        Select All</mat-checkbox>
                                    </div>
                                        <mat-option 
                                        (click)="individualCheckboxChanged()"
                                        *ngFor="let geofence of geoList"  [value]="geofence.employeeId">
                                         {{geofence.employeeNumber}}-{{geofence.firstName}} {{geofence.lastName}}
                                        </mat-option>
                                </mat-select> -->
                                <!-- <input 
                               formControlName="employee"
                                      class="form-control email-input select-placeholder"
                                placeholder="Select employee"
                                [matAutocomplete]="auto"
                                (keyup)="empSearch($event)"
                                style="height: 50px;
                                margin-top: 5px;
                                font-size: 15px;"
                              /> -->
                              <!-- <mat-autocomplete #auto="matAutocomplete"> -->
                                <!-- <mat-option value="All" (click)="empAllSelect()"  >All</mat-option> -->
                               
                                <!-- <mat-option
                                *ngFor="let emp of userlist"  [value]="emp.employee?.employeeNumber + ' - ' + emp.name"
                                  (click)="empSelect(emp)"
                                >
                                {{emp.employee?.employeeNumber}}-{{emp.name}}</mat-option>
                                <mat-option
                                  [value]="null"
                                  *ngIf="employeeNotFound"
                                  (click)="noEmployeeFound()"
                                  >No Employee Found</mat-option
                                >
                              </mat-autocomplete>
                             -->
                            
                              
                        <!-- </mat-form-field>  -->
                    <!-- <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span>  -->
                <!-- </div> -->
            <div class="col-lg-1 col-md-1 col-sm-12">
                <button type="button" class="btn btn-primary btm-sm" (click)="search()"  style="position: relative;bottom:-12px;font-size: 14px;">Search</button>
            </div>
        </div> 
      <div  class="table-responsive" >
        <table mat-table [dataSource]="dataSource" *ngIf="filterData?.length != 0  && tableShow">
            <div class="col-md-12">
            <!-- <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>Sl.No</b> </th>
                <td mat-cell  *matCellDef="let element; let i =index"> {{i+1}}  </td> 
            </ng-container> -->
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
                  <b>SI.NO</b>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ currentPageOffset + i + 1 }}
                </td>
            </ng-container>
              
    
            <!-- Name Column -->
            <ng-container matColumnDef="clientCode" >
                <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>CLIENT CODE</b> </th>
              <td mat-cell  *matCellDef="let element" style="white-space: nowrap;">{{element.clientCode}}</td>
            </ng-container>
            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>CLIENT NAME</b> </th>
              <td mat-cell  *matCellDef="let element" style="white-space: nowrap;">{{element.clientName}}</td>
              </ng-container>
              <!-- <ng-container matColumnDef="EmployeeNumber">
                <th mat-header-cell *matHeaderCellDef  class="col-md-2 col-sm-2"> <b>EMPLOYEE NUMBER</b> </th>
                <td mat-cell   *matCellDef="let element">  {{ element?.employees?.length > 0 ? element.employees[0].employeeNumber : '-' }}</td>
            </ng-container> -->
    
            <!-- <ng-container matColumnDef="EmployeeName">
                <th mat-header-cell *matHeaderCellDef  class="col-md-2 col-sm-2"> <b>EMPLOYEE NAME</b> </th>
                <td mat-cell   *matCellDef="let element">{{element.employees[0]?.user.name}}</td>
            </ng-container> -->
            <ng-container matColumnDef="EmployeeName">
              <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>EMPLOYEE NAME</b> </th>
              <td mat-cell *matCellDef="let element"> 
                <button *ngIf="element?.employees?.length !=0; else nodata" type="button" class="btn b1" style="font-size: 12px;"
                (click)="viewEmployees(element.employees)">
                  View Employees
                </button>
                <ng-template #nodata>--</ng-template>

                <!-- <button mat-icon-button aria-label="Example icon-button with a menu"   [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button> -->
                <!-- <div class="side_setting"> -->
  
                  <!-- <mat-menu #beforeMenu="matMenu" xPosition="before"> -->
                    <!-- <a  mat-menu-item  (click)="editLocation(element)" > <span class="material-icons v"
                      style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span> Edit 
                      Client</a> -->
                    <!-- <a  mat-menu-item  (click)="viewEmployees(element)" *ngIf ="element?.locationDetails.length !=0">
                      <span class="material-icons v" style="    transform: translateY(7px);font-size: 22px;;margin-right:3px" >visibility</span>View Address</a>               
                  </mat-menu> -->
                  <!-- </div> -->
              </td>               
          </ng-container>
        
            <!-- <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>ACTION</b> </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button  [matMenuTriggerFor]="beforeMenu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <div class="side_setting">
                        
                      <mat-menu #beforeMenu="matMenu" xPosition="after">
                        <a  mat-menu-item    [routerLink]="['/create-client-assign']"> 
                          
                          Edit 
                          Client Assign</a>
                        </mat-menu>
                    </div>
                  </td>
            </ng-container> -->
        </div>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> 
             
            <!-- <tr class="mat-row" *matNoDataRow>
                <td class="pl-3 pr-3" colspan="4" align="center">
                    <div class="alert alert-secondary" style="height: 60px;width: 115%;">
                      <h4 class="pt-2">No Client Assign List Found</h4>
                    </div>
                </td>
            </tr> -->
            <tr class="p-3" *ngIf="nodata">
                <td class="no-data card text-center">
                    <div class="card-body">No Client Assign List Found</div> 
                </td>
            </tr> 
            <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" id="nodata" style="text-align: center;">No data found
                <td>
            </tr> -->

        </table>
      </div>
    </form>
    <div class="p-3" *ngIf="nodata">
        <div class="no-data card text-center">
            <div class="card-body">No Data</div> 
        </div>
    </div> 
         <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5,10,20,30,50,100]"></mat-paginator>
        <!-- <mat-paginator  [pageSizeOptions]="[5,10,20,30,50,100]"   showFirstLastButtons ></mat-paginator>  -->
   </div>
</div>
  