import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MessagingService } from 'src/app/service/messaging.service';
import Swal from 'sweetalert2';
import { UserService } from '../Components/user/user.service';
import { AddressComponent } from '../Components/address/address.component';
import { ViewLocationComponent } from '../view-location/view-location.component';
import { ViewAliasComponent } from '../view-alias/view-alias.component';
// import { AddressComponent } from '../../address/address.component';
// import { UserService } from '../../user/user.service';




@Component({
  selector: 'app-client-location-access',
  templateUrl: './client-location-access.component.html',
  styleUrls: ['./client-location-access.component.scss']
})
export class ClientLocationAccessComponent implements OnInit {

  displayedColumns: string[] = ['sino', 'clientid', 'clientname','viewLocation' ,'edit'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false;
  isLoading: boolean = false;
  @ViewChild(MatPaginator) paginator:any;
  currentPageOffset: number = 0; 
  organizationId:any;
  constructor(public dialog: MatDialog,  private gs: UserService,  private Gs: GlobalserviceService ,private auth: AuthService , private router : Router ,private messagingService: MessagingService) { }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {

    let user = this.auth.getLocalStorageData()
       console.log(user);

       this.organizationId = user.organization.organizationId;
       console.log('user',user);
       console.log('organizationId', this.organizationId);
      this.Gs.getClientbyOragnization(this.organizationId).subscribe((a: any) => {
        this.dataSource = new MatTableDataSource(a);
        console.log('data',a)
        this.dataSource.paginator = this.paginator;
      })

  }

  // editLocation(element:any){
  //   this.router.navigate(['client-loc-access', element])

   
  // }

  editLocation(element: any) {
    this.router.navigate(['client-loc-access', element.clientId], {
      // queryParams: { clientName: element.clientName, clientCode: element.clientCode }
    });
  }

  viewAliasNameAddress(element:any)
  {
    console.log('element',element);
    
    const dialogRef = this.dialog.open(ViewAliasComponent, {
      disableClose: false,
      width: 'auto',
      data : element
    });
  }
  viewaddress(element:any)
  {

    console.log('element',element);
    
    const dialogRef = this.dialog.open(ViewLocationComponent, {
      disableClose: false,
      width: '400px',
      data : element
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

}
