import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-viewlocatioaddress',
  templateUrl: './viewlocatioaddress.component.html',
  styleUrls: ['./viewlocatioaddress.component.scss']
})
export class ViewlocatioaddressComponent implements OnInit {
  storeLocationDetails: any;

  constructor(public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log("data",this.data);
    

    this.storeLocationDetails=this.data.locationDetails.address;
    console.log( " this.storeLocationDetails::",this.storeLocationDetails);
    

  }
  

}
