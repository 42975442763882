import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MessagingService } from 'src/app/service/messaging.service';
import Swal from 'sweetalert2';
import { UserService } from '../user/user.service';
import { AddressComponent } from '../address/address.component';
// import { AddressComponent } from '../../address/address.component';
// import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-log-inout-reports',
  templateUrl: './log-inout-reports.component.html',
  styleUrls: ['./log-inout-reports.component.scss']
})
export class LogInoutReportsComponent implements OnInit {

  displayedColumns: string[] = ['locationId', 'latitude', 'login data','longitude', 'logout'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator:any;
  currentPageOffset: number = 0; 
  org: any;
  users: any;
  user1: any;
  allUsers:any[]=[];
  userId:any;
  userName:any;
  employeeId:any;
  constructor(public dialog: MatDialog,  private gs: UserService,  private Gs: GlobalserviceService ,private auth: AuthService , private router : Router ,private messagingService: MessagingService) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {

    let user = this.auth.getLocalStorageData()
    this.org=user.organization.organizationId;
    this.user1=user.userId
    this.employeeId = user?.employee?.employeeId;
    console.log('empId',  this.employeeId);
    
    // this.users=user.userType[0].userTypeId
    this.users = [];
      user.userType.forEach((type) => {
        this.users.push(type.userTypeId);
        this.allUsers.push(type);
      });
       console.log(user);
       console.log(this.org,this.users)

      // this.Gs.get_location(this.user1).subscribe((a: any) => {
      //   this.dataSource = new MatTableDataSource(a);
      //   console.log(a)
      //   this.dataSource.paginator = this.paginator;
      // })
   this.fetchReports();
  }

  editLocation(element:any){
    this.router.navigate(['edit-location', element.locationId])
  }


  viewaddress(element:any)
  {
    const dialogRef = this.dialog.open(AddressComponent, {
      disableClose: false,
      width: '400px',
      data : element,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

  fetchReports(): void {

    this.allUsers.forEach((a)=>{
        console.log(a);
        this.userId=a.userTypeId;
        this.userName=a.userTypeName;
        
    })

    if (this.userId === 1 && this.userName === 'PRIMARY USER') {
      this.Gs.getLoginLogoutReportsforPrimaryUser(this.org).subscribe((d:any)=>{
        console.log(d)
        
      //   d.forEach((item) => {
      //     console.log(item.loginTime);
      //     console.log(item.employee.firstName)
      // });
  
      this.dataSource = new MatTableDataSource(d);
      
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate+data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
  
  
      })
    }

    if (this.userId === 2 && this.userName === 'BUDDY USER') {
      this.Gs.getLoginLogoutReportsforBuddyUser(this.users).subscribe((d:any)=>{
        console.log(d)
        
      //   d.forEach((item) => {
      //     console.log(item.loginTime);
      //     console.log(item.employee.firstName)
      // });
  
      this.dataSource = new MatTableDataSource(d);
      
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate+data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
  
  
      })
    }

    if (this.userId === 3 && this.userName === 'SECONDARY USER') {
      this.Gs.getLoginLogoutReportsforEmployee(this.employeeId).subscribe((d:any)=>{
        console.log(d)
        
      //   d.forEach((item) => {
      //     console.log(item.loginTime);
      //     console.log(item.employee.firstName)
      // });
  
      this.dataSource = new MatTableDataSource(d);
      
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate+data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
  
  
      })
    }

    // this.Gs.get_loginReport(this.org,this.users).subscribe((d:any)=>{
    //   console.log(d)
      
  

    // this.dataSource = new MatTableDataSource(d);
    
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.filterPredicate = (data: any, filter: string) => {
    //       const dataStr = (data.loginTime + data.employee.user.name
    //         + data.loginDate+data.logoutTime).toLowerCase();
    //       return dataStr.includes(filter);
    //     };


    // })
    
  }

}
