<div>
    <!-- <ul style="list-style-type: none;">
      <li class="m-3" *ngFor="let location of data?.locationDetails">
          <span class="mr-3 font-weight-bold fs-4">*</span>
        {{location.locationDetails.address}}
       
      </li>
    </ul> -->
<ul>
  <li>{{storeLocationDetails}}</li>
</ul>  </div>
  
  