import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild,Inject} from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { MatDialog , MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ViewTrackUserListComponent } from '../view-track-user-list/view-track-user-list.component';
import { DatePipe } from '@angular/common';
import { UserLocationComponent  } from '../user-location/user-location.component';
import { ExcelService } from 'src/app/excel.service';


import { AddressDialogComponent } from '../../address-dialog/address-dialog.component';


@Component({
  selector: 'app-track-user-list',
  templateUrl: './track-user-list.component.html',
  styleUrls: ['./track-user-list.component.scss']
})
export class TrackUserListComponent implements OnInit {
  reportForm : FormGroup;
  // displayedColumns: string[] = ['userId', 'date', 'day', 'time', 'latitude', 'address','view'];
  displayedColumns: string[] = ['userId', 'date', 'employeeId', 'employeeName', 'empcontactNo','siteName','siteAddress','supervisorFofficer','officerContactNo','shiftName','totalworkedHours'];

  dataSource = new MatTableDataSource;
  selection: any = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator:MatPaginator;
  
  userlist :any = [];
  p: number = 1;
  // p:any;
//  date: Date;
    user_required:boolean = false;
    startDate_required:boolean = false;
    staDate_required:boolean =false;
     endDate_required: boolean = false;
    endDate_error:boolean = false;
    starttime_required:boolean = false;
    endtime_required: boolean = false;
    user: any;
    startDate: any;
    endDate: any;
    stTime: any;
    edTime: any;
    userNameList: any=[];
    userData: any=[];
    filterData: any=[];
    nodata = false;
    totalDistance: string;
    distance: number;
    totDis: string;
    repostData: any=[];
    // pageNum: number = 1;
    mySelect:any =5;
    polygonpoints: any=[];
    currentPageOffset: number = 0;
  dataExport: any =[];
  exportingdata: any=[];
  exported:any;
    // beforesearchpage: any;

   constructor(private gs: GlobalserviceService, 
    private fb:FormBuilder, 
    private auth: AuthService,
     private router: Router, 
     private es:ExcelService,
     private datePipe: DatePipe,
     public dialog: MatDialog,
     
     private datepipe:DatePipe) 
     {
      this.reportForm;
      }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
      // this.dataSource.paginator = this.paginator;
      // this.p = 1;
      let  userdata = this.auth.getLocalStorageData();
      console.log(userdata);
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata .userId)
      this.reportForm = this.fb.group({
        user:[''],
        startdate:[''],
        enddate:[''],
        starttime:[''],
        endtime:['']
      })
    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata .userId)
      this.gs.get_user_org(id).subscribe((a: any) => {
          //  console.log(a);
          //  this.userNameList=a;
          //  console.log(this.userNameList);
           a.map(
             (e: any)=>{
                 if(userdata.userType[0].userTypeName == "BUDDY USER")
                 {
                  if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") 
                    && e.buddyUser?.userId == userdata.userId){
                    console.log(e);
                    this.userlist.push(e);
                  }
                 }
                 else {
                  if(e.userId !=  userdata.userId){
                    console.log(e)
                     this.userlist.push(e);
                  }
                 }
            }
          )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    } else {
        
      let id = this.auth.getLocalStorageData()?.userId;
      this.gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any)=>{
           if(e.userId != id){
           //  console.log(e)
              this.userlist.push(e);
           }
         }
       )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    }
  }

  // exportToExcel(){
  //   // let keywords = this.reportForm.value.keywords;
  //   // // let jobType = this.reportForm.value.jobType;

  //   // if (keywords ) {
  //     this.dataExport.length = 0;
  //     this.exportingdata = this.filterData;
  //     console.log(" this.exportingdata::", this.exportingdata);

  //   for (let element of this.exportingdata) {
  //     // console.log('exportingdata.clientId', exportingdata.clientId);
    
  //     // Extract noticePeriod values for the current exportingdata
  //     // const noticePeriodGet = exportingdata.noticePeriod.map(notice => notice.listTypeValueName);

  //     let clientNames = '';
  //     // Loop through each client in the clients array
  //     if (element.user.employee?.clients?.length) {
  //         for (let client of element.user.employee.clients) {
  //             clientNames += client.clientName + ', '; // Concatenate client names
  //         }
  //         // Remove the trailing comma and space
  //         clientNames = clientNames.slice(0, -2);
  //     }
   
  //     this.exported = {
  //       FromDate:this.datePipe.transform(element.date, 'yyyy-MM-dd'),
  //       // TODate:exportingdata.date,
  //       EmployeeName: element.user.name,
  //       EmployeeId: element.user.employee?.employeeNumber? element.user.employee.employeeNumber : '',
  //       EmpContactNo: element.user?.phoneNumber ? element.user.phoneNumber : '',
  //       SiteName: clientNames? clientNames : '' ,
  //       SiteAddress: element.siteAddress? element.siteAddress: '',
  //       Supervisor: element.buddyUser?.name? element.buddyUser.name :'',
  //       SupContactNo: element.buddyUser?.phoneNumber ? element.buddyUser.phonenumber : '' ,
  //       Shiftname:element.shiftName? element.shiftName :'',
  //       ShiftsWorked:element.Worked? element.Worked :'',
  //       TotalHoursWorked : element.user.employee.employeeShiftLoginDetails.totalWorkedHours? element.user.employee.employeeShiftLoginDetails.totalWorkedHours :'',
        
  //       // PositionName: exportingdata.positionName,
  //       // MinExperience: exportingdata.minExperience,
  //       // MaxExperience: exportingdata.maxExperience,
  //       // Location: exportingdata.location,
  //       // MaxCTC: exportingdata.ctc,
  //       // JobType: exportingdata.jobType.listTypeValueName,
  //       // NoticePeriod: noticePeriodGet.join(','),
  //       // PositionStatus:this.positionStatusInExcel,
  //       // Status:this.StatusInExcel

  //     }
    
  //     this.dataExport.push(this.exported);
  //   }
  //   console.log("this.dataExport",this.dataExport);
  //   this.es.exportAsExcelFile(this.dataExport, 'Export');
  //  } 

  exportToExcel() {
    this.dataExport.length = 0;
    this.exportingdata = this.filterData;
    console.log("this.exportingdata::", this.exportingdata);

    for (let element of this.exportingdata) {
        let clientNames = '';
        let siteAddresses = '';
        let shiftNames = '';
        let totalHoursWorked = 0;

        // Loop through each client in the clients array
        if (element.user.employee?.clients?.length) {
            for (let client of element.user.employee.clients) {
                clientNames += client.clientName + ', '; // Concatenate client names
                siteAddresses += client.addressLine1 ? client.addressLine1 + ', ' : ''; // Concatenate site addresses

                // Loop through each shift in the client's shift array
                if (client.shift?.length) {
                    for (let shift of client.shift) {
                        shiftNames += shift.shiftName + ', '; // Concatenate shift names
                    }
                }
            }
            // Remove trailing commas and spaces
            clientNames = clientNames.slice(0, -2);
            siteAddresses = siteAddresses.slice(0, -2);
            shiftNames = shiftNames.slice(0, -2);
        }

        // Calculate total worked hours from employeeShiftLoginDetails
        if (element.user.employee?.employeeShiftLoginDetails?.length) {
            for (let shiftDetail of element.user.employee.employeeShiftLoginDetails) {
                totalHoursWorked += shiftDetail.totalWorkedHours;
            }
        }

        this.exported = {
            FromDate: this.datePipe.transform(element.date, 'yyyy-MM-dd'),
            EmployeeName: element.user.name,
            EmployeeId: element.user.employee?.employeeNumber ? element.user.employee.employeeNumber : '',
            EmpContactNo: element.user?.phoneNumber ? element.user.phoneNumber : '',
            SiteName: clientNames ? clientNames : 'No clients',
            SiteAddress: siteAddresses ? siteAddresses : 'No address',
            Supervisor: element.buddyUser?.name ? element.buddyUser.name : '',
            SupContactNo: element.buddyUser?.phoneNumber ? element.buddyUser.phoneNumber : '',
            Shiftname: shiftNames ? shiftNames : 'No shifts',
            ShiftsWorked: element.Worked ? element.Worked : '',
            TotalHoursWorked: totalHoursWorked ? totalHoursWorked : 'No worked hours'
        };

        this.dataExport.push(this.exported);
    }
    console.log("this.dataExport", this.dataExport);
    this.es.exportAsExcelFile(this.dataExport, 'Export');
}

  
  viewLocation()
  {
    console.log("overall Location:",this.filterData);
    this.repostData=this.filterData;
    console.log(this.repostData);
    const dialogRef = this.dialog.open(UserLocationComponent, {
      width: '800px',
      height:'500px',
      // data:this.repostData
      data:
      {
        latLongArray:this.repostData,
      }
      });
  }
  
  showmap(repData:any)
  {
    console.log("show client Location:",repData);
    // this.repostData.length = 0;
    this.repostData=[];
    // this.polygonpoints=[];
  //  arr.length = 0
    console.log(this.repostData);
    console.log(this.polygonpoints);
    this.repostData.push(repData);
    console.log(this.repostData);
    const dialogRef = this.dialog.open(UserLocationComponent, {
      width: '800px',
      height:'500px',
      //  data:this.repostData,
      data:
      {
        latLongArray:this.repostData,
        pointsArray:this.polygonpoints
      }
      });
  }
 
  search()
  {
    console.log(this.reportForm);
    console.log("search");
    if((this.reportForm.value.user==""||this.reportForm.value.user==0))
    {
      console.log("user Invaild");
      this.user_required=true;
    }
    else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate!=""&&this.reportForm.value.enddate==""))
    {
      console.log("user Invaild in enddate");
      this.endDate_required=true;
    }
    else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate==""&&this.reportForm.value.enddate!=""))
    {
      console.log("user Invaild in startDate");
      this.staDate_required=true;
    }
    else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate!=""&&this.reportForm.value.enddate!=""&&this.reportForm.value.starttime!=""&&this.reportForm.value.endtime==""))
    {
      console.log("user Invaild ebd Time");
      // this.endtime_required=true;
    }
    else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate!=""&&this.reportForm.value.enddate!=""&&this.reportForm.value.starttime==""&&this.reportForm.value.endtime!=""))
    {
      console.log("user Invaild start Time");
      // this.starttime_required=true;
    }
    else if((this.reportForm.value.user==""||this.reportForm.value.user==0)&&this.reportForm.value.startdate==""&&this.reportForm.value.enddate==""&&this.reportForm.value.starttime==""&&this.reportForm.value.endtime=="")
    {
       console.log("Required",this.user);
       this.user_required=true;
    }
    
    else if(this.reportForm.value.user!=""&&this.reportForm.value.startdate!=""&&this.reportForm.value.enddate!=""&&this.reportForm.value.starttime!=""&&this.reportForm.value.endtime!="")
    {
      console.log("Both All Api",this.user);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate",date);
      var stardate= this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate",stardate);
      var enddate= this.datePipe.transform(date, 'yyyy-MM-dd');
      console.log("enddate",enddate);
      let st=this.reportForm.value.starttime+":00";
      console.log(this.stTime);
      let et=this.reportForm.value.endtime+":59";
      console.log(et);
      this.gs.get_userandTimelist(stardate,enddate,st,et,this.user)
      .subscribe((data: any) =>{
        console.log(data);
        this.distance=data[0].totalDistance;
        console.log(this.distance);
        this.totDis=this.distance.toString().slice(0,6);
        console.log(this.totDis);
        this.totalDistance=this.totDis;
        console.log(this.totalDistance);
        this.nodata=false;
        this.filterData=data;
        console.log(this.filterData);
        this.dataSource = new MatTableDataSource(this.filterData);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
        console.log(this.dataSource);
        // user ploygon points Draw Api
        this.gs.get_usergeofencelist(this.user)
        .subscribe((data: any) =>{
          console.log(data);
          if(data.errorCode==null)
          {
          console.log(data);
          this.polygonpoints=data;
          console.log(this.polygonpoints);
          }
          if(data.errorCode==204){
            console.log("204 No Data",this.filterData);
          }
        },
        (error) =>{
          console.log("error");
          if(error.error.errorCode==204){
            console.log("204 No Data",this.filterData);
          }
        })
      },
      (error) =>{
        console.log("error");
        if(error.error.errorCode==204){
          console.log("204 No Data",this.filterData);
          this.filterData.length=0;
          console.log(this.filterData);
          this.nodata = true;
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
        }
      })
    }

    else if(this.reportForm.value.user!="" && this.reportForm.value.startdate!="" && this.reportForm.value.enddate!="")
    {
      console.log("user and Date Api",this.user);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate",date);
      var stardate= this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate",stardate);
      var enddate= this.datePipe.transform(date, 'yyyy-MM-dd');
      console.log("enddate",enddate);
      
      this.gs.get_userandDatelistv2(stardate,enddate,this.user)
      .subscribe((data: any) =>{
        console.log(data);
        this.distance=data[0].totalDistance;
        console.log(this.distance);
        this.totDis=this.distance.toString().slice(0,6);
        console.log(this.totDis);
        this.totalDistance=this.totDis;
        console.log(this.totalDistance);
        this.nodata=false;
        this.filterData=data;
        console.log(this.filterData);
        this.dataSource = new MatTableDataSource(this.filterData);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
        console.log(this.dataSource);
        // this.gs.get_usergeofencelist(this.user)
        // .subscribe((data: any) =>{
        //   console.log(data);
        //   if(data.errorCode==null)
        //   {
        //   console.log(data);
        //   this.polygonpoints=data;
        //   console.log(this.polygonpoints);
        //   }
        //   if(data.errorCode==204){
        //     console.log("204 No Data",this.filterData);
        //   }
        // },
        // (error) =>{
        //   console.log("error");
        //   if(error.error.errorCode==204){
        //     console.log("204 No Data",this.filterData);
        //   }
        // })
      },
      (error) =>{
        console.log("error");
        if(error.error.errorCode==204){
          // this.userlist =0;
          console.log("204 No Data",this.filterData);
          this.filterData.length=0;
          console.log(this.filterData);
          this.nodata = true;
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
        }
      })
    }
    else if(this.reportForm.value.user!="")
    {
      console.log("user Api",this.user);
      // this.polygonpoints=[];
      this.gs.get_userlist(this.user)
      .subscribe((data: any) =>{
        console.log(data);
        // console.log(data.user.employee.clients.addressLine1);
        this.distance=data[0].totalDistance;
        this.totDis=this.distance.toString().slice(0,6);
        this.totalDistance=this.totDis;
        this.nodata=false;
        this.filterData=data;
        console.log(this.filterData);
        this.dataSource = new MatTableDataSource(this.filterData);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;

        this.dataSource.filterPredicate = (data: any, filter: string) => {
          
          const dataStr = (data.date+data.user.phoneNumber+data.user.employee.employeeNumber+data.user.name).toLowerCase();
          return dataStr.includes(filter);
        };

        this.gs.get_usergeofencelist(this.user)
        .subscribe((data: any) =>{
          console.log(data);
          if(data.errorCode==null)
          {
          this.polygonpoints=data;
          console.log(this.polygonpoints);
          }
          if(data.errorCode==204){
            console.log("204 No Data",this.filterData);
          }
        },
        (error) =>{
          if(error.error.errorCode==204){
            console.log("204 No Data",this.filterData);
          }
        })
      },
      (error) =>{
        if(error.error.errorCode==204){
          //this.userlist =0;
          console.log("204 No Data",this.filterData);
          this.filterData.length=0;
          this.nodata = true;
          this.dataSource = new MatTableDataSource(this.filterData);
          this.dataSource.paginator = this.paginator;
        }
      })
    }
  }

  isselected(event:any){
    this.user_required=false;
    console.log("user",event.value);
    // this.submitted=false;
    this.user=event.value;
    console.log(this.user);
  }

  startdate(event:any)
  {
    this.staDate_required=false;
    console.log("startDate",event.value);
    let startDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startDate=startDate;
    console.log(this.startDate);
    console.log(this.endDate);
    if(this.endDate!=undefined)
    {
      console.log("End Date not null");
    if(this.endDate>=this.startDate)
    {
      this.endDate_error=false;
      console.log("vaild Date");
    }
    else
    {
      this.endDate_error=true;
      console.log("Invaild Date");
    }
   }
  }

  enddate(event:any)
  {
    this.endDate_required=false;
    console.log("endDate",event.value);
    let endDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(endDate);
    this.endDate=endDate;
    console.log(this.endDate);
    if(this.endDate>=this.startDate)
    {
      this.endDate_error=false;
      console.log("vaild Date");
    }
    else
    {
      this.endDate_error=true;
      console.log("Invaild Date");
    }
  }
  
  
  // onPaginateChange(event:any){
  //   console.log((JSON.stringify("Current page index: " + event.pageIndex)));
  //   this.pageNum=event.pageIndex;
  //   console.log("this.pageNum",this.pageNum);
  // }

//   startTime(event:any) {
//   console.log(event);
//   console.log(event.target.value);
//  }
//  viewStartTime(event:any)
//  {
//   console.log("click",event);
//   console.log(event.target.value);
//  }
  stime(event:any) 
  {
    // this.starttime_required=false;
    console.log("startTime");
    console.log("startTime",event);
    this.stTime=event;
    console.log(this.stTime);
  }

  endTime(event:any)
  {
    // this.endtime_required=false;
    console.log("endTime",event.value);
    this.edTime=event.value;
    console.log(this.edTime);
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  selectedUser() {
    console.log(this.selection.selected)
    const dialogRef = this.dialog.open(ViewTrackUserListComponent, {
      width: '750px',
      data: this.selection.selected,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  /* mat select */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: any) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      // console.log(x.selected.length)
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  

  openDialog(clients: any[], type: string): void {
    this.dialog.open(AddressDialogComponent, {
      data: {
        clients: clients,
        type: type  
      }
    });
  }
}
