import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alias-dialog',
  templateUrl: './alias-dialog.component.html',
  styleUrls: ['./alias-dialog.component.scss']
})
export class AliasDialogComponent implements OnInit {
  aliasForm: FormGroup;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AliasDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.aliasForm = this.fb.group({
      aliasName: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.aliasForm.valid) {
      // Close the dialog and return the alias name
      this.dialogRef.close(this.aliasForm.value.aliasName);
    } else {
      // Show an error message if the form is invalid
      this.errorMessage = 'Alias name is required.';
    }
  }

  // onCancel() {
  //   // Instead of closing the dialog, you can just show an error message
  //   if (this.aliasForm.get('aliasName')?.invalid) {
  //     this.errorMessage = 'Alias name is required.';
  //   } else {
  //     this.dialogRef.close(); // You can close if no error
  //   }
  // }

}
