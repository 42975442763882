<div>
    <ul style="list-style-type: none;">
      <li class="m-3" *ngFor="let location of data?.locationDetails; let i = index">
        <div *ngIf="location?.address">
          <span class="mr-3 font-weight-bold fs-4">*</span>{{ location?.address }}
        </div>
        <!-- <ng-template #noAddress>
          <div>No Address Found</div>
        </ng-template> -->
      </li>
    </ul>
  </div>
  
  