import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalserviceService } from '../providers/globalservice.service';
import Swal from 'sweetalert2';
import { Router, RouterLink } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { AuthService } from '../Authenticaton/auth.service';
import { ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ViewlocatioaddressComponent } from '../viewlocatioaddress/viewlocatioaddress.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss'],
})
export class ShiftComponent implements OnInit {
  locationName: any;
  stroreShiftId: any;
  locationList: any = [];
  userId: any;
  storeOrgId: any;
  storeClientId: any;
  nodata: boolean=false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  endDate_error: boolean = false;
  startDate: any;
  endDate: any;
  staDate_required: boolean = false;
  startDate_required: any;
  endDate_required: boolean = false;
  clicentName: any;
  enddate1: any;
  startdate1: any;
  tableShow: boolean = false;
  p: number = 1;
  mySelect: number = 5;
  currentPageOffset: number = 0;
  dataSource = new MatTableDataSource<any>([]); // Initialize MatTableDataSource with employee data
  input: any;
  displayedColumns: string[] = [
    'sino',
    'startDate',
    'endDate',
    'shift',
    'employeeName',
    'clientName',
    'locationAdd',
  ];
  geoList: any = [];
  empList: any[] = [];
  shifts: any = [];
  btndisable: boolean = true;
  shiftid: any;
  employee: any;
  isClientSelected: boolean = false;
  employeeNotFound: any;
  submitted1: any;
  empolyeeapproverexist: any;
  postdatamultiple: never[];
  employeeId: any;

  private unsubscribe$: Subject<void> = new Subject<void>();
  employeelistsub: any;
  empolyeereadonly: boolean = false;
  disableFeildforAll: boolean = false;
  readonlyDisable: boolean = false;
  selectedEmployeeData: any[] = [];
  storeLocationId: any;

  constructor(
    private datepipe: DatePipe,
    private gs: GlobalserviceService,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) {}
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    let userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    this.userId = userdata.userId;
    this.storeOrgId = userdata.organization.organizationId;
    console.log('this.storeOrgId', this.storeOrgId);
    this.gs.get_client_org(this.storeOrgId).subscribe((a: any) => {
      console.log(a);
      this.geoList=a;
      this.geoList = Array.isArray(a) ? a : [a];

    })
    // this.gs.get_employee_byClient_list(1).subscribe((d: any) => {
    //   console.log('Employee response:', d);
    //   // this.employee=d
    //   this.btndisable=false;
    //   this.empList=d
    //   // Add selectedShift field for each employee to bind to the shift select element
    //   // this.empList = Array.isArray(d) ? d.map(emp => ({ ...emp, selectedShift: null })) : [];
    //   this.dataSource = new MatTableDataSource(this.empList); // Update data source
    //   this.tableShow = true; // Show table
    // });
    this.isselecteduser(event);
    this.isselecteShifts(event);
    // this.isselectedname(event);
    // this.isselectedemployesname(event)
  }
  location(clientId: string) {
    this.gs
      .get_location_byClient(this.storeClientId)
      .subscribe((response: any) => {
        console.log(response);
        this.locationList = response;
      });
  }
  // dataSource = [
  //   { name: '1108-USHA', shift: 'Morning shift' },

  // ]
  search() {
    console.log('this.startdate::', this.startdate1);
    console.log('this.end date::', this.enddate1);
    console.log('this.clicentName::', this.clicentName);
    console.log('this.location::', this.locationName);

    // console.log("this.employee::",this.employee);

    if (
      this.startdate1 == undefined &&
      this.enddate1 == undefined &&
      this.clicentName == undefined &&
      this.locationName == undefined &&
      this.shiftid == undefined
    ) {
      alert('Please select Any Field to Search!');
      return;
    } else if (this.startdate1 !== undefined && this.enddate1 == undefined) {
      alert('Please Select End Date!');
      return;
    } else {
      if (this.startdate1 == undefined && this.enddate1 !== undefined) {
        alert('Please Select Start Date!');
        return;
      }
    }

    if (
      this.startdate1 !== undefined &&
      this.enddate1 !== undefined &&
      this.clicentName == undefined &&
      this.locationName == undefined &&
      this.shiftid == undefined
    ) {
      this.nodata=false;

      console.log('only dates !!!');

      this.gs
        .shift_onlyDate_fields(this.startdate1, this.enddate1)
        .subscribe((a:any) => {
          console.log(a, 'a');
          this.selectedEmployeeData = a;
          this.tableShow = true;
          this.nodata=false;

          this.dataSource = new MatTableDataSource(a);
          this.dataSource.paginator = this.paginator;
          if (a?.statusCode == 204) {
            this.selectedEmployeeData = [];
            this.nodata = true;
            this.tableShow=false
          }

        });
    } else if (
      this.startdate1 == undefined &&
      this.enddate1 == undefined &&
      this.clicentName !== undefined &&
      this.locationName == undefined &&
      this.shiftid == undefined
    ) {
      this.nodata=false;

      console.log('only client calling!!!');
      this.gs.shift_onlyClient_fields(this.clicentName).subscribe((a:any) => {
        console.log(a, 'a');
        this.tableShow = true;
        this.selectedEmployeeData=a;
        this.tableShow=true;
        this.nodata=false;

        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }
      });
    } else if (
      this.startdate1 == undefined &&
      this.enddate1 == undefined &&
      this.clicentName == undefined &&
      this.locationName == undefined &&
      this.shiftid !== undefined
    ) {
      this.nodata=false;

      console.log('only shift calling!!!');

      this.gs.get_onlyShift(this.shiftid).subscribe((a:any)=>{
        console.log(a, 'a');
        this.tableShow = true;
        this.selectedEmployeeData=a;
        this.tableShow=true;
        this.nodata=false;
        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }
      })
    } else if (
      this.startdate1 !== undefined &&
      this.enddate1 !== undefined &&
      this.clicentName == undefined &&
      this.locationName == undefined &&
      this.shiftid !== undefined
    ) {
      this.nodata=false;

      console.log('dates with shift!!');
      this.gs.get_shift_with_dates( this.shiftid,this.startdate1, this.enddate1).subscribe((a:any)=>{
        console.log(a, 'a');
        this.tableShow = true;
        this.selectedEmployeeData=a;
        this.tableShow=true;
        this.nodata=false;

        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }
      })
    } else if (
      this.startdate1 !== undefined &&
      this.enddate1 !== undefined &&
      this.clicentName !== undefined &&
      this.locationName == undefined &&
      this.shiftid !== undefined
    ) {
      this.nodata=false;

      console.log('dates with client , shift!!');
      this.gs.get_shift_with_dates_client(this.clicentName,this.shiftid,this.startdate1,this.enddate1).subscribe((a:any)=>{
        console.log(a, 'a');
        this.tableShow = true;
        this.selectedEmployeeData=a;
        this.tableShow=true;
        this.nodata=false;

        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }

      })
    } else if (
      this.startdate1 == undefined &&
      this.enddate1 == undefined &&
      this.clicentName !== undefined &&
      this.locationName !== undefined &&
      this.shiftid == undefined
    ) {
      this.nodata=false;

      console.log('  client , location !!');
      this.gs.get_client_location(this.clicentName,this.locationName).subscribe((a:any)=>{
        console.log(a, 'a');
        this.tableShow = true;
        this.selectedEmployeeData=a;
        this.tableShow=true;
        this.nodata=false;

        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }

      })
    } else if (
      this.startdate1 == undefined &&
      this.enddate1 == undefined &&
      this.clicentName !== undefined &&
      this.locationName !== undefined &&
      this.shiftid !== undefined
    ) {
      this.nodata=false;

      console.log(' loc client , shift!!');
      this.gs.get_shift_with__client_location(this.clicentName,this.shiftid,this.locationName).subscribe((a:any)=>{
        console.log(a, 'a');
        this.tableShow = true;
        this.selectedEmployeeData=a;
        this.tableShow=true;
        this.nodata=false;

        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }
      })
    } else if (
      this.startdate1 !== undefined &&
      this.enddate1 !== undefined &&
      this.clicentName !== undefined &&
      this.locationName == undefined &&
      this.shiftid == undefined
    ) {
      this.nodata=false;

      console.log('date with client!!');
      this.gs
        .shift_ClientDate_fields(
          this.clicentName,
          this.startdate1,
          this.enddate1
        )
        .subscribe((a:any) => {
          console.log(a, 'a');
          this.tableShow = true;
          this.nodata=false;
          this.nodata=false;

          this.selectedEmployeeData=a
          this.dataSource = new MatTableDataSource(a);
          this.dataSource.paginator = this.paginator;
          if (a?.statusCode == 204) {
            this.selectedEmployeeData = [];
            this.nodata = true;
            this.tableShow=false
          }
        });
    }
    else if(this.startdate1!==undefined&& this.enddate1!==undefined&&this.clicentName!==undefined&&this.locationName!==undefined&&this.shiftid==undefined){
      console.log("dates with client,location!!");
      this.nodata=false;

      this.gs.get_client_dates_location(this.clicentName,this.locationName,this.startdate1,this.enddate1).subscribe((a:any)=>{
        console.log(a, 'a');
          this.tableShow = true;
          this.nodata=false;
          
          this.selectedEmployeeData=a
          this.dataSource = new MatTableDataSource(a);
          this.dataSource.paginator = this.paginator;
          if (a?.statusCode == 204) {
            this.selectedEmployeeData = [];
            this.nodata = true;
            this.tableShow=false
          }
      })
      

    }
    else if(this.startdate1==undefined&&this.enddate1==undefined&&this.clicentName!==undefined&&this.shiftid!==undefined&&this.locationName==undefined){
      console.log("shift & client calling!!!");

      this.gs.get_Client_Shift(this.clicentName,this.shiftid).subscribe((a:any)=>{
        this.tableShow = true;
        this.nodata=false;
        this.selectedEmployeeData=a
        this.dataSource = new MatTableDataSource(a);
        this.dataSource.paginator = this.paginator;
        if (a?.statusCode == 204) {
          this.selectedEmployeeData = [];
          this.nodata = true;
          this.tableShow=false
        }
      })


      
    }
    
    else if (
      this.startdate1 !== undefined &&
      this.enddate1 !== undefined &&
      this.clicentName !== undefined &&
      this.locationName !== undefined &&
      this.shiftid !== undefined
    ) {
      this.nodata=false;

      console.log('all came here!!');

      this.gs
        .shift_All_fields(
          this.clicentName,
          this.shiftid,
          this.locationName,
          this.startdate1,
          this.enddate1
        )
        .subscribe((a: any) => {
          console.log(a, 'a');
          this.tableShow = true;
          this.nodata=false;

          this.selectedEmployeeData = a;
          this.dataSource = new MatTableDataSource(a);
          this.dataSource.paginator = this.paginator;

          console.log(a.statusCode);
          console.log(a.description);
          
          

          if (a?.statusCode == 204) {
            this.selectedEmployeeData = [];
            this.nodata = true;
            this.tableShow=false
          }
        });
    } else {
      console.log('all came out here!!!');
    }
  }
  onPageChange(event: any) {
    console.log("index",event.pageIndex);
    console.log("size",event.pageSize);
    this.currentPageOffset = event.pageIndex * event.pageSize;
    }
  changefirstpage() {
    this.p = 1;
  }
  viewclientaddress(element:any)
  {
    console.log('element',element);
    
    const dialogRef = this.dialog.open(ViewlocatioaddressComponent, {
      disableClose: false,
      width: '400px',
      data : element
    });
  }
  isselecteShifts(event) {
    this.gs.get_shift_byClient_list().subscribe((d: any) => {
      console.log('Employee response:', d);
      // this.shifts=d;
      this.stroreShiftId = this.shifts.shiftId;
      console.log('   this.stroreShiftId', this.stroreShiftId);

      this.shifts = Array.isArray(d) ? d : [d];
      // this.dataSource = new MatTableDataSource(this.shifts); // Update data source
      this.tableShow = true; // Show table
    });
  }
  clearEmpArray() {
    this.empList = [];
  }

  empSearch(event) {
    // this.empolyeereadonly=false;
    this.postdatamultiple = [];
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);

    // Employee List API
    if (employeenumber.length >= 2) {
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.gs
        .getEmployeeNameList(event.target.value)
        .pipe(
          takeUntil(this.unsubscribe$), 
          switchMap(() => this.gs.getEmployeeNameList(event.target.value))
        )
        .subscribe((result: any) => {
          this.empList = [];

          if (result.statusCode) {
            console.log(result.statusCode);
            this.empList = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            this.empList = result;
            if (this.empList.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }
        });
    }
  }
  // deleteemployee(index) {
  //   this.employee=null;
  //   this.empolyeeapproverexist=false;
  //   this.btndisable=true;
  //   if (index != -1) {
  //     this.selectedEmployeeData.splice(index, 1);

  //   }
  // }
  deleteemployee(index: number) {
    if (index !== -1) {
      // Remove the selected employee from the array
      this.selectedEmployeeData.splice(index, 1);

      // Update the MatTableDataSource to reflect changes
      this.dataSource.data = [...this.selectedEmployeeData];

      // You can add any additional logic for handling empty employee data, etc.
      if (this.selectedEmployeeData.length === 0) {
        this.tableShow = false;
        this.btndisable = true; // Disable buttons if no employees are left
      }
    }
  }

  empAllSelect() {
    if (this.employee === 'All') {
      // Disable the field immediately after selecting "All"
      this.disableFeildforAll = true;
      this.empolyeereadonly = true;
      this.readonlyDisable = true;

      // Fetch the employee list
      this.gs.get_employee_byClient_list(1).subscribe(
        (response: any) => {
          console.log('Employee response:', response); // Log the response for debugging

          // Check if the response is an array and assign it properly
          if (Array.isArray(response)) {
            this.empList = response;
            this.selectedEmployeeData = this.empList;
            // Update the MatTableDataSource
            this.dataSource.data = [...this.selectedEmployeeData]; // Spread to trigger change detection

            console.log('this.datasourceeeeeeeeeee::', this.dataSource);

            this.tableShow = true; // Show the table
            this.btndisable = false; // Enable buttons if needed
          } else {
            console.error('Response is not an array', response);
          }
        },
        (error) => {
          console.error('Error fetching employee list', error); // Handle error
        }
      );
    }
  }

  empSelect(emp) {
    // Reset some flags and fields
    this.disableFeildforAll = false;
    this.isClientSelected = false;

    // Log the selected employee
    console.log(emp);
    this.tableShow = true;

    // Store selected employee details
    this.employeeId = emp.employeeId;
    this.employee = `${emp.employeeNumber} - ${emp.firstName}`;
    console.log(this.employee);

    if (emp) {
      console.log('if emp came here!!!');

      // Check if employee already exists in the selectedEmployeeData array
      const exists = this.selectedEmployeeData.find(
        (e) => e.employeeId === emp.employeeId
      );

      if (!exists) {
        console.log('if not existed, came here!!!');

        // Add the selected employee to the array only if it does not exist
        this.selectedEmployeeData.push(emp);
      } else {
        console.log('else already exists, came here!!!');
        this.empolyeeapproverexist = true; // Show error message for existing employee
      }

      // Update the MatTableDataSource
      this.dataSource.data = [...this.selectedEmployeeData]; // Spread to trigger change detection
    }

    // Check if a client is selected
    if (this.clicentName) {
      console.log('Client selected');
      this.isClientSelected = true; // Enable fields if a client is selected
      this.tableShow = true; // Show the table

      this.gs.get_employee_byClient_list(1).subscribe((d: any) => {
        console.log('Employee response:', d);
        this.empList = d;
        this.btndisable = false;

        // Ensure the selected employee is added only once
        if (
          !this.selectedEmployeeData.find(
            (e) => e.employeeId === emp.employeeId
          )
        ) {
          this.selectedEmployeeData.push(emp);
        }

        // Update the data source
        this.dataSource.data = [...this.selectedEmployeeData]; // Spread operator to update MatTableDataSource
        console.log('this.datasource::', this.dataSource);
      });
    } else {
      console.log('No client selected');
      this.employee = null; // Clear employee field if no client is selected

      // Check if the employee is already selected
      const hasEmployee = this.selectedEmployeeData.some(
        (e) => e.employeeId === emp.employeeId
      );
      if (!hasEmployee) {
        this.selectedEmployeeData.push(emp); // Add employee to the selected list
        console.log('Updated selectedEmployeeData', this.selectedEmployeeData);
      } else {
        console.log('Employee already exists in the list');
      }

      // Ensure the data source for the table is updated
      this.dataSource.data = [...this.selectedEmployeeData]; // Update the data source for the table
    }

    // Uncomment if you want to manage validators
    // if (this.selectedEmployeeData.length >= 1) {
    //     this.employee?.clearValidators();
    // } else {
    //     this.employee?.setValidators([Validators.required]);
    // }
    // this.employee?.updateValueAndValidity();
  }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.employeeId = undefined;
    this.employee = undefined;
  }

  onClientSelection(event: any) {
    this.tableShow = false;
    const selectedClientId = event.value;

    // Enable employee dropdown only if a valid client is selected
    if (selectedClientId && selectedClientId !== '0') {
      this.isClientSelected = true;
      this.tableShow = false;

      // Call the API to get the list of employees for the selected client
      this.getEmployeesByClient(selectedClientId);
      this.location(selectedClientId);
    } else {
      this.isClientSelected = false;
      this.locationName=undefined
      this.empList = []; // Clear employee list if no valid client is selected
    }
  }
  getEmployeesByClient(clientId: string) {
    this.tableShow = false;
    // API call to fetch employees for the selected client
    this.gs.get_employee_byClient_list(this.storeOrgId).subscribe((d: any) => {
      console.log('Employee response:', d);
      // this.employee=d
      // this.btndisable=false;
      this.tableShow = false;
      this.empList = d;
      // Add selectedShift field for each employee to bind to the shift select element
      // this.empList = Array.isArray(d) ? d.map(emp => ({ ...emp, selectedShift: null })) : [];
      // this.dataSource = new MatTableDataSource(this.empList); // Update data source
      // this.tableShow = true; // Show table
    });
  }
  isSelectLocation(event) {
    // this.storeLocationId=event.value
  }
  isselectedname(event) {
    // this.user_required=false;
    // console.log("user",event.value);
    // this.submitted=false;
    // this.geoList=event.value;
    this.storeClientId = event.value;
    this.tableShow = false;
    console.log(this.geoList);
    this.gs.get_client_org(this.storeOrgId).subscribe((a: any) => {
      this.tableShow = false;
      console.log(a);
      this.geoList = a;
      this.geoList = Array.isArray(a) ? a : [a];

      // this.geoList.forEach((client: any) => {
      //   this.storeClientId = client.clientId;
      //   console.log(this.storeClientId);
      // });
    });
  }
  isselectedemployesname(event: any) {
    const selectedEmpId = event.value;
    const selectedEmp = this.empList.find(
      (emp) => emp.employeeId === selectedEmpId
    );

    if (selectedEmp) {
      // Access the data array from MatTableDataSource to check for duplicates
      // const dataArray = this.dataSource.data;
      // const alreadyExists = dataArray.some(emp => emp.employeeId === selectedEmpId);
      // if (!alreadyExists) {
      //   dataArray.push(selectedEmp); // Add the employee to the array
      //   // this.dataSource.data = [...dataArray]; // Update the dataSource to trigger change detection
      // } else {
      //   this.empolyeeapproverexist = true; // Show "Approver Already Exist" message
      //   console.log("Approver Already Exists");
      // }
    }
  }
  isselecteduser(event) {}
  // submit(){
  //   console.log("submir came here!!!");
  //   if (this.empList.length === 0) {
  //     Swal.fire({
  //       text: 'No employee data to submit!',
  //       icon: 'error',
  //       timer: 2000,
  //     });
  //     return; // Exit the function if there's no data
  //   }
  //   else{

  //     const payload = this.empList.map((employee) => ({
  //       startDate: this.startdate1,
  //       endDate: this.enddate1,
  //       client: {
  //         clientId: this.clicentName
  //       },
  //       status: {
  //         listTypeValueId: 1 // Example value, can be dynamic
  //       },
  //       shift: {
  //         shiftId: this.stroreShiftId // Selected shift for each employee
  //       },
  //       employee: {
  //         employeeId: employee.employeeId // Employee ID
  //       },
  //       locationDetails:{
  //         locationId:1
  //       }
  //     }));

  //     console.log('Payload:', payload);
  //     return

  //     this.gs.assign_Employee_shifts(payload).subscribe((a: any) => {
  //       console.log(a);
  //       if(a.statusCode == 200)
  //         {
  //           this.btndisable=true;

  //          // this.submitted=false;
  //          Swal.fire({
  //            text:a.description,
  //            icon:'success',
  //            timer: 2000,
  //          })
  //          setTimeout(() => {
  //           this.empList = []; // Clear employee list
  //           // this.dataSource = new MatTableDataSource(this.empList); // Clear the table data
  //           this.tableShow = false; // Hide the table if needed
  //         }, 2000);

  //       }
  //     })

  //   }

  // }
  enddate(event: any) {
    this.endDate_required = false;
    console.log('endDate', event.value);
    let endDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(endDate);
    this.endDate = endDate;
    this.enddate1 = endDate;
    console.log(this.endDate);
    if (this.endDate >= this.startDate) {
      this.endDate_error = false;
      console.log('vaild Date');
    } else {
      this.endDate_error = true;
      console.log('Invaild Date');
    }
  }
  startdate(event: any) {
    this.staDate_required = false;
    console.log('startDate', event.value);
    let startDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startDate = startDate;
    this.startdate1 = startDate;
    console.log(this.startDate);
    console.log(this.endDate);
    if (this.endDate != undefined) {
      console.log('End Date not null');
      if (this.endDate >= this.startDate) {
        this.endDate_error = false;
        console.log('vaild Date');
      } else {
        this.endDate_error = true;
        console.log('Invaild Date');
      }
    }
  }
}
