import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-view-alias',
  templateUrl: './view-alias.component.html',
  styleUrls: ['./view-alias.component.scss']
})
export class ViewAliasComponent implements OnInit {
viewLocationDetails:any[]=[];
viewLocationDetails2:any[]=[];
displayedColumns: string[] = ['sino', 'aliasname', 'address'];
dataSource = new MatTableDataSource;
@ViewChild(MatPaginator) paginator:any;
currentPageOffset: number = 0; 
  constructor(public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any) { }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    console.log('data', this.data);
    this.viewLocationDetails.push(this.data);

    // if (Array.isArray(this.data)) {
      this.viewLocationDetails.forEach((r) => {
        // Loop through each item in r.locationDetails
        r.locationDetails.forEach((detail) => {
          // Push each detail into viewLocationDetails2
          this.viewLocationDetails2.push(detail);
        });
      });
      
    // } else {
    //   console.error('this.data is not an array:', this.data);
    //   // Optionally, initialize it as an empty array to avoid further errors
    //   this.data = [];
    // }
    this.dataSource = new MatTableDataSource(this.viewLocationDetails2);
    console.log('viewLocationDetails',      this.viewLocationDetails2);
      console.log('dataSource',this.dataSource);

      this.dataSource.paginator = this.paginator;
  }

  passElement(element:any){
console.log('element',element);

  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }


}
